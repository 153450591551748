.header-link,
.header-link:hover {
  color: unset;
}

.avasisLogo {
  width: 300px;
  height: auto;
}

.responsive-table {
  width: 100%;
  /* Make the table expand to the container width */
  table-layout: auto;
  /* Allow the table to adjust its layout automatically */
  border-collapse: collapse;
  /* Remove spacing between table cells */
}

.responsive-table th {
  width: 33.33%;
  /* Set each th to one-third of the table width */
  padding: 10px;
  /* Add padding for better spacing */
}

.header-container {
  padding: 0px 0px 0px 0px !important;
  margin: auto 0px;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 3); */
}

.table-container {
  overflow-x: auto;
  /* Enable horizontal scrolling if needed */
}

tr.header-first-row {
  background-color: white;
}

div.header-second-row > h1 {
  font-size: 48px;
  font-weight: bolder;
  color: white;
}

div.header-second-row > span {
  font-size: 28px;
  font-weight: normal;
  color: white;
  line-height: 1;
}
