/* .navButton {
  background-color: red;
}

.navButton:hover {
  background-color: #735f9e !important;
} */

.navButton {
  background: white !important;
  border-radius: 8px;
  scale: 0.8;
}

.navButton.p-button {
  background: #735f9e;
  border: #735f9e;
}
