.license-not-valid {
  color: red;
}

.license-expiring {
  color: orange;
}

.licenseTable-card {
  background-color: #e7e8ed !important;
  color: black !important;
  margin-left: unset;
  margin-top: 10vh;
  margin-bottom: 10vh;
  border: 1px solid #735f9e;
  min-height: 45vh;
}

.profileTags {
  color: #735f9e;
  text-align: left;
  font-weight: 900 !important;
}
.profileValue {
  text-align: left;
}

.licenseButton {
  font-size: 12px;
}
