div.welcomeDiv {
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 21px;
  line-height: 2.5;
}

ul.home-list {
  margin-left: 10px;
  line-height: 1.5;
}

.extensionButton {
  width: 250px;
  height: 40px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 21px;
}
