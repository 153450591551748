div.copyright {
  display: flex;
  justify-content: flex-end; /* Schiebt den Inhalt horizontal nach rechts */
  align-items: flex-end;
  padding: 0;
  font-size: 16px;
}

div.footer-first-row {
  font-size: 18px;
}
