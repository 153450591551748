.profile-card {
  background-color: #e7e8ed !important;
  color: black !important;
  margin-left: unset;
  margin-top: 10vh;
  margin-bottom: 10vh;
  border: 1px solid #735f9e;
  min-height: 45vh;
}

.info-row {
  display: flex;
  justify-content: space-between; /* Ensures even spacing between the heading and the corresponding text */
  margin-bottom: 10px; /* Adds some space between each row */
}

.info-row h4 {
  margin: 0; /* Removes default margin */
  flex: 0 0 40%; /* Ensures the heading takes up 40% of the row width */
  text-align: right; /* Aligns the text to the left */
}

.info-row span {
  flex: 1; /* Ensures the text takes up the remaining space */
  text-align: left; /* Aligns the text to the left */
  margin-left: 20px; /* Adds some space between the heading and text */
}

.forgotPasswordButton {
  width: 250px;
  height: 40px;
  font-size: 21px;
}

.profile-dialog-labels {
  color: #351f65;
  font-size: 18px;
}

.profile-dialog-input {
  font-family: Verdana;
  letter-spacing: 0.125em;
  color: #351f65;
  background: rgb(150 131 191 / 20%);
  border: 1px solid #351f65;
}

.profile-dialog {
  width: 40em;
  border-radius: 12px;
}

.profile-dialog-content {
  border-radius: 12px;
  background-color: white;
  border: 1px solid #351f65;
}

.profile-dialog-passwordChecklist {
  color: #351f65;
  font-weight: bold;
}
