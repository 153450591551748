/* .p-treetable-tbody > tr {
  border-bottom: 1px solid #d9d9d9;
} */

.file-table .p-treetable-wrapper{
  border-radius: 5px;
  border: 1px solid lightgrey;
}

.file-table .p-treetable-tbody > tr > td{
  padding: 0.5rem;
}

.file-table .p-treetable-thead > tr > th {
  background-color: #735F9E;
  color:white;
}

.file-table .p-sortable-column-icon {
  color:white;
}