.skeleton-heading {
  margin-left: auto;
  margin-right: auto;
}

.p-panel-header.info-panel-header {
  color: white;
  background-color: #735f9e;
  cursor: pointer;
}

.extension-header {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.p-panel .p-panel-header .p-panel-header-icon.info-panel-toggler {
  background-color: white;
  border-radius: 10px;
}

.p-panel .p-panel-header .p-panel-header-icon.info-panel-toggler:hover {
  background-color: #f0f0f0;
}

.extensioninfo-tooltip.p-tooltip .p-tooltip-text {
  box-shadow: none !important;
  font-size: 15px;
  padding: 0.25rem 0.5rem;
}
