.extension-template {
  display: flex;
}
.extension-template-title {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
.extension-template-tag {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}
.extension-template-button {
  /* align-content: center; */
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-end;
  /* margin-left:300px; */
}

.column-extension-name {
  text-align: left;
  padding-left: 30px;
  line-height: 1;
  width: 30rem;
  min-width: 30rem;
}

.extension-button .p-button:hover {
  box-shadow: 3px 4px 4px rgb(0 0 0 / 35%);
  background: #351f65;
}

.p-treenode-content {
  padding: 0.1rem;
}

.node-header > div {
  font-size: 21px;
}

.extension-tag {
  min-width: 25px;
  padding: 3px;
  font-size: 10px;
}

.extension-date-tag,
.extension-new-tag {
  min-width: 25px;
  padding: 3px;
  font-size: 10px;
  margin-left: 5px;
}

.messages {
  display: -webkit-inline-box;
  display: inline-block;
}

.container > div > ul.p-tree-container {
  padding-left: 0px;
}

.extension-short-description {
  text-align: left;
  font-family: "AvenirNextLTPro-Thin", sans-serif;
  display: grid;
  align-items: center;
}

.extension-node {
  width: 100%;
  min-width: 60rem;
  background-color: #f2f2f2;
  padding: 10px;
  cursor: pointer;
}

.extension-node:hover {
  background-color: #e6e6e6;
}

.extensionlist-tooltip.p-tooltip .p-tooltip-text {
  box-shadow: none !important;
  font-size: 15px;
  padding: 0.25rem 0.5rem;
}
